import {constructServerSideProps} from 'utils/ssr';
import {useI18n} from '@halp/foundation/i18n';
import {Page, Heading} from '@halp/ui';

export default function Page404() {
	const {t} = useI18n();
	return (
		<Page>
			<Heading align="center" spacing="lg">
				404
			</Heading>
			<Heading type="h4" color="blurple" align="center" spacing="lg">
				{t('404')}
			</Heading>
		</Page>
	);
}

export const getStaticProps = constructServerSideProps();
